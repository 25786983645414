import React, {useState, useEffect} from "react";
import './Directory.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faDownload, faArrowRight, faFolder} from '@fortawesome/free-solid-svg-icons'
import config from "../config";

const FileComponent = ({name, path, vfsId, idToken, fetchContents, setLoading}) => {

    let fileKey
    if (path.length === 1) {
        fileKey = name
    } else {
        fileKey = path.slice(1,path.length).join("/") + "/" + name
    }
    
    const downloadItem = async (e) => {

        const fileHandle = await window.showSaveFilePicker({
            suggestedName: name,
        });

        const writableStream = await fileHandle.createWritable();   
        
        const url = config.apiEndpoint + "vfs/" + vfsId + "/files/" + fileKey
        
        const body = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + idToken
            },
        }).then(response => {
            if (response.ok) {
                return response.body
            } else {
                throw new Error(response.text)
            }
        }).catch(error => 
            console.error(error)
        )

        const reader = body.getReader();

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            await writableStream.write(value);
        }
        await writableStream.close();



    };

    const deleteItem = async (e) => {
        
        console.log('Delete Requested on ', fileKey)
        setLoading(true)

        fetch(config.apiEndpoint + "vfs/" + vfsId + "/files/" + fileKey, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + idToken
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Delete failed')
            }
        })
        .then(response => {
            setLoading(true)
            fetchContents()
            console.log('success: ',response)
        })
        .catch(error => {
            setLoading(false)
            console.error(error)
        })
    }
    return(
        <div key={name} className="file">
            <FontAwesomeIcon icon={faArrowRight} style={{flex: 1, color: "gray"}}/>
            <a key={name+"-p"} style={{flex: 20}}>{name}</a>
            <FontAwesomeIcon icon={faDownload} style={{flex: 1}} onClick={downloadItem} className="icon"/>
            <FontAwesomeIcon icon={faTrash} className="icon" style={{flex: 1}} onClick={deleteItem}/>
        </div>
    )
}

const FolderComponent = ({name, vfsId, path, setPath, setLoading, fetchContents, idToken}) => {

    const deleteItem = async (e) => {

        e.stopPropagation()
        
        let key
        if (path.length === 1) {
            key = name
        }
        else {
            key = path.slice(1) + "/" + name
        }
        console.log('Delete Requested on ', key)
        setLoading(true)

        fetch(config.apiEndpoint + "vfs/" + vfsId + "/files/" + key, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + idToken
            }
        }).then(response => {
            if (response.ok) {
                return response.text
            } else {
                throw new Error('Delete failed')
            }
        }).then(response => {
            setLoading(true)
            fetchContents()
            console.log('success: ',response)
        }).catch(error => {
            setLoading(false)
            console.error(error)
        })
    }

    const handleClick = () => {
        setPath([...path, name])
        setLoading(true)
    }

    return(
        <div key={name} className="folder" onClick={handleClick}>
            <FontAwesomeIcon icon={faFolder} style={{flex: 1, color: "gray"}}/>
            <a key={name+"-p"} style={{flex: 20}}>{name}</a>
            <FontAwesomeIcon icon={faTrash} className="icon" style={{flex: 1}} onClick={deleteItem}/>
        </div>
    )
}

const DirectoryView = ({path, vfsId, setPath, contents, setContents, idToken, setLoading, fetchContents, style}) => {

    const folderList = []
    const components = [] 

    if (contents){
        for (const dir of contents.directories) {
            folderList.push(dir)
        }
        folderList.sort()
        for (const folder of folderList) {
            components.push(
                <FolderComponent 
                    name={folder} 
                    vfsId={vfsId}
                    path={path} 
                    setPath={setPath}
                    key={folder}
                    setLoading={setLoading}
                    fetchContents={fetchContents}
                    idToken={idToken}
                />
            )
        }

        const fileList = []
        for (const file of contents.files) {
            fileList.push(file)
        }
        fileList.sort()
        for (const file of fileList) {
            components.push(
                <FileComponent 
                    name={file} 
                    key={file} 
                    vfsId={vfsId}
                    path={path}
                    idToken={idToken}
                    fetchContents={fetchContents}
                    setLoading={setLoading}
                />
            )
        }
    }

    return(
        <div style={style} className="directory">
            {components}
        </div>
    )
}

const CurrentDirectoryPath = ({path, setPath, setLoading}) => {

    const handleClick = (i) => {
        const newPath = [...path.slice(0, i+1)]
        setPath(newPath);
        setLoading(true)
    }
    const folders = []
    if (path){

        for (let i = 0; i < path.length; i++) {
            const component = path[i]
            folders.push(
                <div key={i} onClick={() => handleClick(i)}>
                    {component + '/'}
                </div>
            )
        }
    }
    
    return (
        <div className="path">
            {folders}
        </div>
    )
}

const Directory = ({style, resource, command, idToken, loading, setLoading, path, setPath}) => {

    const [contents, setContents] = useState(null)

    const fetchContents = () => {
        if (resource && idToken) {
            console.log('Fetching directory contents...')

            let url = config.apiEndpoint + resource.resource + "/" + resource.id + command + "/" 
            if (path.length > 1) {
                url += path.join('/')
            }
            console.log(url)
            fetch(url, {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + idToken
                    }
                }
            )
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('empty directory')
                }
            })
            .then( files => {
                setContents(files)
                setLoading(false)
                console.log('success')
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
        }
    }

    useEffect(() => {
        fetchContents()
    }, [resource, path])

    return(
        <div style={style} className="directory-container">
            <CurrentDirectoryPath 
                path={path} 
                setPath={setPath}
                contents={contents}
                setContents={setContents}
                setLoading={setLoading}
            />
            {loading ?
                <div> Loading contents... </div>
            :
                <DirectoryView 
                    path={path} 
                    setPath={setPath}
                    contents={contents}
                    setContents={setContents}
                    vfsId={resource.id}
                    idToken={idToken}
                    setLoading={setLoading}
                    fetchContents={fetchContents}
                />
            }
        </div>
        
    )
}

export default Directory