/*
https://betterprogramming.pub/how-to-implement-files-drag-and-drop-in-react-22cf42b7a7ef
*/
import React, {useState} from 'react';
import config from '../config';
import './FilesDragAndDrop.css';
import pLimit from 'p-limit';




const RequestMultipartUpload = async (url, idToken, remoteFile, numParts, fileSizeBytes, chunkSize, setErr) => {


  const result = fetch(url, {
    method: "POST",
    headers: {
        "Authorization": "Bearer " + idToken
    },
    body: JSON.stringify({
      key: remoteFile,
      num_parts: numParts
    })
  }).then(response => {   
    if (response.ok) {
      return response.json()
    }
  }).then(response => {
    const uploadId = response.UploadId
    const urls = response.urls
    return [uploadId, urls]
  }).catch(err => {

    if (err.response) {
      setErr(`Error: ${err}, ${err.text}`)
      console.error(err)
    } else {
      chunkSize = chunkSize * 2
      numParts = Math.ceil(fileSizeBytes / chunkSize)
      
      if (chunkSize > config.MAX_CHUNK_SIZE_BYTES) {
        throw new Error("Chunk size too large")
      }

      console.log(`Increasing chunk size to ${chunkSize / 1024 / 1024} MB`)
      return RequestMultipartUpload(url, idToken, remoteFile, numParts, fileSizeBytes, chunkSize)
    }
  })

  return await result
}

const UploadPartsInParallel = async (localFile, urls, chunkSize, setUploadProgress, setMessage, startTime, setErr) => {

  const totalParts = urls.length
  let finishedParts = 0
  const uploadedParts = []

  const CONCURRENT_REQUESTS = 100
  const limit = pLimit(CONCURRENT_REQUESTS);


  const UploadPart = async (partNumber, presignedUrl, chunkSize, localFile, startTime, setUploadProgress, setMessage, setErr, retries) => {
    const startingByte = (partNumber - 1) * chunkSize
    const chunk = localFile.slice(startingByte, startingByte + chunkSize)
  
    setUploadProgress(0)
    const status = limit(() => fetch(presignedUrl, {
      method: "PUT",
      body: chunk
    }).then(response => {
      if (response.ok) {       
  
        finishedParts ++
        const endTime = Date.now()
        const elapsedTimeSeconds = Math.floor((endTime - startTime) / 1000)
        const elapsedTimeMinutes = Math.floor(elapsedTimeSeconds / 60)
        const elapsedTimeHours = Math.floor(elapsedTimeMinutes / 60)
  
        let timeMessage
        if (elapsedTimeHours > 0) {
          timeMessage = `${elapsedTimeHours}h:${elapsedTimeMinutes % 60}m:${elapsedTimeSeconds % 3600}s`
        } else if (elapsedTimeMinutes > 0) {
          timeMessage = `${elapsedTimeMinutes}m:${elapsedTimeSeconds % 60}s`
        } else {
          timeMessage = `${elapsedTimeSeconds}s`
        }
  
        setUploadProgress(finishedParts / totalParts)
        setMessage(`Uploading... (${timeMessage})`)
  
        return {ETag: response.headers.get("ETag"), PartNumber: partNumber}
      } else {
        setErr(`Error: ${response}, ${response.text}`)
        throw new Error(`Error uploading part: ${response}, ${response.status}`)
      }
    })).catch(err => {

      console.log("Retrying", retries)
      retries -= 1
      if (retries < 0) {
        throw new Error("Max Retries Exceeded")
      } else {
        return UploadPart(partNumber, presignedUrl, chunkSize, localFile, startTime, setUploadProgress, setMessage, setErr, retries)
      }
    })
    return status
  }
  
  setMessage("Uploading...")
  urls.forEach(async ([partNumber, presignedUrl]) => {
    const status = UploadPart(partNumber, presignedUrl, chunkSize, localFile, startTime, setUploadProgress, setMessage, setErr, 5)

  //   const startingByte = (partNumber - 1) * chunkSize
  // const chunk = localFile.slice(startingByte, startingByte + chunkSize)

  // setUploadProgress(0)
  // const status = limit(() => fetch(presignedUrl, {
  //   method: "PUT",
  //   body: chunk
  // }).then(response => {
  //   if (response.ok) {       

  //     finishedParts ++
  //     const endTime = Date.now()
  //     const elapsedTimeSeconds = Math.floor((endTime - startTime) / 1000)
  //     const elapsedTimeMinutes = Math.floor(elapsedTimeSeconds / 60)
  //     const elapsedTimeHours = Math.floor(elapsedTimeMinutes / 60)

  //     let timeMessage
  //     if (elapsedTimeHours > 0) {
  //       timeMessage = `${elapsedTimeHours}h:${elapsedTimeMinutes % 60}m:${elapsedTimeSeconds % 3600}s`
  //     } else if (elapsedTimeMinutes > 0) {
  //       timeMessage = `${elapsedTimeMinutes}m:${elapsedTimeSeconds % 60}s`
  //     } else {
  //       timeMessage = `${elapsedTimeSeconds}s`
  //     }

  //     setUploadProgress(finishedParts / totalParts)
  //     setMessage(`Uploading... (${timeMessage})`)

  //     return {ETag: response.headers.get("ETag"), PartNumber: partNumber}
  //   } else {
  //     setErr(`Error: ${response}, ${response.text}`)
  //     throw new Error(`Error uploading part: ${response}, ${response.status}`)
  //   }
  // }))
    uploadedParts.push(status)
  })
    
  return await Promise.all(uploadedParts)
}

const CompleteUpload = async (url, idToken, remoteFile, uploadedParts, uploadId) => {

  fetch(url, {
    method: "POST",
    headers: {
        "Authorization": "Bearer " + idToken
    },
    body: JSON.stringify({
      key: remoteFile,
      complete: "true",
      parts: uploadedParts,
      UploadId: uploadId
    })
  }).then(response => {
    if (!response.ok) {
      throw new Error("failed to complete")
    }
  })
}


const Loader = ({uploadProgress, fileName, message, err}) => {

  return (
    <div className='loader'>
      <p>Uploading {fileName}</p>
       <progress value={uploadProgress}/>
       <p>{err === null ? message : err}</p>
     </div>
   )
}

function FilesDragAndDrop({activeVFS, idToken, children}) {

  const drop = React.useRef(null)
  const drag = React.useRef(null)
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false) 
  const [uploadProgress, setUploadProgress] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [message, setMessage] = useState(null)
  const [err, setErr] = useState(null)

    const onUpload = (files) => {

        const baseUrl = config.apiEndpoint + "vfs/" + activeVFS.id + "/files/"

        if (activeVFS == null) {
          throw new Error("ActiveVfs is not yet defined")
        }
        
        Array.from(files).forEach(async (file) => {
          setUploading(true)
          setFileName(file.name)
          setMessage("Preparing upload")
          const startTime = Date.now()

          let chunkSize = config.MIN_CHUNK_SIZE_BYTES
          if (chunkSize < config.MIN_CHUNK_SIZE_BYTES) {
            throw new Error("Chunk size is too small")
          }

          const fileSizeBytes = file.size
          let numParts = Math.ceil(fileSizeBytes / chunkSize)

          const url = baseUrl + file.name
          const [uploadId, urls] = await RequestMultipartUpload(url, idToken, file.name, numParts, fileSizeBytes, chunkSize, setErr)
          const uploadedParts = await UploadPartsInParallel(file, urls, chunkSize, setUploadProgress, setMessage, startTime, setErr)
          setMessage("Finalizing")
          await CompleteUpload(url, idToken, file.name, uploadedParts, uploadId)
          console.log("complete")

          setUploading(false)
          setUploadProgress(null)
          setFileName(null)
          setMessage(null)
        })        
    }

  

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)

    const {files} = e.dataTransfer
    if (files && files.length) { 
        onUpload(files)
    }
  }
  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target !== drag.current) {
        setDragging(true)
    }
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target === drag.current) {
        setDragging(false)
    }
  }

  React.useEffect(() => {

    const instance = drop.current;

    if (instance) {
      instance.addEventListener('dragover', handleDragOver)
      instance.addEventListener('drop', handleDrop)
      instance.addEventListener('dragenter', handleDragEnter)
      instance.addEventListener('dragleave', handleDragLeave)        
    }
    return () => {
      instance.removeEventListener('dragover', handleDragOver)
      instance.removeEventListener('drop', handleDrop)
      instance.removeEventListener('dragenter', handleDragEnter)
      instance.removeEventListener('dragleave', handleDragLeave)
    }
  }, [])

  return (
    <div ref={drop} className='overlayContainer'>
        {dragging && (
            <div ref={drag} className='overlay'>
            Drop here to upload file
            <span
              role='img'
              aria-label='emoji'
              className='area__icon'
            >
              &#128526;
            </span>
          </div>
        )}
        {uploading && <Loader uploadProgress={uploadProgress} fileName={fileName} message={message} err={err}/>}
      {children}
    </div>
  );
}

export default FilesDragAndDrop